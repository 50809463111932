const translations = {
  en: {
    addItem: 'Add Item',
    addNewItem: 'Add New Item',
    analytics: 'Analytics',
    assignTable: 'Assign Table to Guest',
    available: 'available',
    cashier: 'Cashier',
    confirmPayment: 'Confirm Payment',
    customersServed: 'Customers Served',
    dailySummary: 'Daily Summary',
    darkMode: 'Dark Mode',
    delete: 'Delete',
    enableNotifications: 'Enable Notifications',
    enterPin: 'Enter PIN',
    existingUsers: 'Existing Users',
    generatePin: 'Generate Pin',
    generatedPin: 'Generated Pin',
    grandTotal: 'Grand Total',
    guestNamePlaceholder: 'Guest Name',
    inventory: 'Inventory',
    inventoryManagement: 'Inventory Management',
    invalidInputDescription: 'Please fill out all fields with valid values.',
    invalidInputTitle: 'Invalid input.',
    invalidUserProfileInputDescription: "Name and email are required.",
    login: 'Login',
    logout: 'Logout',
    longPollingUpdates: 'Long Polling Updates',
    markAsReady: 'Mark as Ready',
    namePlaceholder: 'Name',
    notifications: 'Notifications',
    occupied: 'occupied',
    orders: 'Orders',
    passwordPlaceholder: 'Password',
    paymentDetailsForTable: 'Payment Details for Table',
    popularItems: 'Popular Items',
    price: 'Price',
    pricePlaceholder: 'Price',
    processPayment: 'Process Payment',
    profile: 'Profile',
    profileUpdatedDescription: 'Your profile has been updated successfully.',
    profileUpdatedTitle: 'Profile Updated',
    quantity: 'Quantity',
    quantityPlaceholder: 'Quantity',
    reservations: 'Reservations',
    reserveTable: 'Reserve a Table',
    reserveTableButton: 'Reserve Table',
    rolePlaceholder: 'Role',
    saveProfile: 'Save Profile',
    saveSettings: 'Save Settings',
    sections: 'Sections',
    selectTable: 'Select Table',
    sendToCashier: 'Send to Cashier',
    settings: 'Settings',
    settingsUpdatedDescription: 'Your settings have been updated successfully.',
    settingsUpdatedTitle: 'Settings Updated',
    showTestNotification: 'Show Test Notification',
    statusPreparing: 'preparing',
    statusReady: 'ready',
    statusSentToCashier: 'sent to cashier',
    table: 'Table',
    tableAssignedDescription: 'Table {table} assigned to {guest}.',
    tableAssignedTitle: 'Table Assigned',
    tableReservedDescription: 'Table {table} reserved for {customer}.',
    tableReservedTitle: 'Table Reserved',
    tableStatus: 'Table Status',
    tablesReadyForPayment: 'Tables Ready for Payment',
    tip: 'Tip',
    total: 'Total',
    totalOrders: 'Total Orders',
    totalRevenue: 'Total Revenue',
    updateMessage: 'Update at',
    updateStatus: 'Update Status',
    userManagement: 'User Management',
    userProfile: 'User Profile',
    usernamePlaceholder: 'Username',
    waiterOrders: 'Orders Statuses',
    webSocketMessages: 'WebSocket Messages',
    yourOrders: 'Your Orders',

    sectionDeletedTitle: "Section Deleted",
    sectionDeletedDescription: "The section has been deleted successfully.",
    errorTitle: "Error",
    errorDeletingSection: "There was an error deleting the section.",
    confirmDelete: "Confirm Delete",
    deleteConfirmationText: "Are you sure you want to delete this section? This action cannot be undone.",
    cancel: "Cancel",
    preparationOrders: "Preparation Orders",
    manageItems: "Manage Items",
    manageCategories: "Manage Categories"
  },
  es: {
    addItem: 'Agregar Artículo',
    addNewItem: 'Agregar Nuevo Artículo',
    analytics: 'Analíticas',
    assignTable: 'Asignar Mesa al Invitado',
    available: 'disponible',
    cashier: 'Caja',
    confirmPayment: 'Confirmar Pago',
    customersServed: 'Clientes Atendidos',
    dailySummary: 'Resumen Diario',
    darkMode: 'Modo Oscuro',
    delete: 'Eliminar',
    enableNotifications: 'Habilitar Notificaciones',
    enterPin: 'Ingresar PIN',
    existingUsers: 'Usuarios Existentes',
    generatePin: 'Generar Pin',
    generatedPin: 'Pin Generado',
    grandTotal: 'Total General',
    guestNamePlaceholder: 'Nombre del Invitado',
    inventory: 'Inventario',
    inventoryManagement: 'Gestión de Inventario',
    invalidInputDescription: 'Por favor, complete todos los campos con valores válidos.',
    invalidInputTitle: 'Entrada no válida.',
    invalidUserProfileInputDescription: "El nombre y el correo electrónico son obligatorios.",
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    longPollingUpdates: 'Actualizaciones de Long Polling',
    markAsReady: 'Marcar como Listo',
    namePlaceholder: 'Nombre',
    notifications: 'Notificaciones',
    occupied: 'ocupada',
    orders: 'Órdenes',
    passwordPlaceholder: 'Contraseña',
    paymentDetailsForTable: 'Detalles de Pago para la Mesa',
    popularItems: 'Artículos Populares',
    price: 'Precio',
    pricePlaceholder: 'Precio',
    processPayment: 'Procesar Pago',
    profile: 'Perfil',
    profileUpdatedDescription: 'Su perfil ha sido actualizado con éxito.',
    profileUpdatedTitle: 'Perfil Actualizado',
    quantity: 'Cantidad',
    quantityPlaceholder: 'Cantidad',
    reservations: 'Reservaciones',
    reserveTable: 'Reservar una Mesa',
    reserveTableButton: 'Reservar Mesa',
    rolePlaceholder: 'Rol',
    saveProfile: 'Guardar Perfil',
    saveSettings: 'Guardar Configuraciones',
    sections: 'Secciones',
    selectTable: 'Seleccionar Mesa',
    sendToCashier: 'Enviar a Caja',
    settings: 'Configuraciones',
    settingsUpdatedDescription: 'Sus configuraciones han sido actualizadas con éxito.',
    settingsUpdatedTitle: 'Configuraciones Actualizadas',
    showTestNotification: 'Mostrar Notificación de Prueba',
    statusPreparing: 'preparando',
    statusReady: 'listo',
    statusSentToCashier: 'enviado a caja',
    table: 'Mesa',
    tableAssignedDescription: 'Mesa {table} asignada a {guest}.',
    tableAssignedTitle: 'Mesa Asignada',
    tableReservedDescription: 'Mesa {table} reservada para {customer}.',
    tableReservedTitle: 'Mesa Reservada',
    tableStatus: 'Estado de las Mesas',
    tablesReadyForPayment: 'Mesas Listas para Pago',
    tip: 'Propina',
    total: 'Total',
    totalOrders: 'Órdenes Totales',
    totalRevenue: 'Ingresos Totales',
    updateMessage: 'Actualización a las',
    updateStatus: 'Actualizar Estado',
    userManagement: 'Gestión de Usuarios',
    userProfile: 'Perfil de Usuario',
    usernamePlaceholder: 'Nombre de Usuario',
    waiterOrders: 'Estatus de ordenes',
    webSocketMessages: 'Mensajes de WebSocket',
    yourOrders: 'Tus Órdenes',

    sectionDeletedTitle: "Seccion borrada",
    sectionDeletedDescription: "La seccion fue borrada exitosamente.",
    errorTitle: "Ups!",
    errorDeletingSection: "Hubo un error borrando la seccion.",
    confirmDelete: "Confirmar borrado",
    deleteConfirmationText: "Esta seguro de borrar la seccion? esta accion no puede ser revertida",
    cancel: "Cancelar",
    preparationOrders: "Ordenes en preparacion",
    manageItems: "Administrar Items",
    manageCategories: "Administrar Categorias",
  }
};

export default translations;
